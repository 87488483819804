
<template>
  <div id="Form">
    <a-form-model
      id="formModel"
      ref="formModel"
      :model="formModel.item"
      :rules="formModel.rules"
      v-bind="{
        labelCol: { span: 0 },
        wrapperCol: { span: 24 },
      }"
    >
      <template v-if="MigrationInfo.migrate_status === 0">
        <a-form-model-item>
          <p
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            "
          >
            <span style="color: #dcdcdc">您当前矿机容量为：</span>
            <span>{{ MigrationInfo.total_power }}</span>
          </p>
        </a-form-model-item>
        <a-form-model-item>
          <p
            style="
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            "
          >
            <span style="color: #dcdcdc">
              根据您的矿机容量，本次迁移须支付：
            </span>
            <span style="text-align: right; width: 100%">
              <span style="font-size: 1.5em !important">
                {{ MigrationInfo.migrate_total_price }}&nbsp;USDT
              </span>
              <span style="display: flex; justify-content: flex-end">
                <span>
                  <span style="color: #dcdcdc">有效算力：</span>
                  <br />
                  <span style="color: #dcdcdc">云算力：</span>
                </span>
                <span>
                  <span style="color: #dcdcdc">20 USDT/TiB</span>
                  <br />
                  <span style="color: #dcdcdc">4 USDT/TiB</span>
                </span>
              </span>
            </span>
          </p>
        </a-form-model-item>
        <a-form-model-item>
          <password-enter
            :pwd.sync="formModel.item.a"
            :show.sync="passwordEnterShow"
            @ok="handleSubmitForm"
          />
          <a-button
            id="form-submit-btn"
            ref="form-submit-btn"
            @click="form_password_enter"
          >
            确认迁移
          </a-button>
        </a-form-model-item>
      </template>
      <template v-else-if="MigrationInfo.migrate_status !== 0">
        <a-form-model-item>
          <p v-if="MigrationInfo.migrate_status === 1">
            恭喜您申请成功，HMpool火速为您筹备，1周后开始进行迁移，迁移日起三周内完成迁移
          </p>
          <p v-else-if="MigrationInfo.migrate_status === 2">
            已完成迁移
          </p>
        
        </a-form-model-item>
      </template>
    </a-form-model>
  </div>
</template>

<script>
import "@/components/js/_form_common.js";
import passwordEnter from "@/components/Password-Enter";
import { getUserServerMigrationInfo } from "@/Vue-Axios/getUser";
import { postUserServerMigrationPayment } from "@/Vue-Axios/postPay";

export default {
  components: {
    passwordEnter,
  },
  data: function () {
    return {
      formModel: {
        item: {
          a: "", // 密码
        },
        rules: {
          a: [
            {
              required: true,
              validator: (rule, value, callback) => {
                if (!value) callback(new Error(""));
                else callback();
              },
              trigger: ["change", "blur"],
            },
          ],
        },
      },
      MigrationInfo: {
        migrate_status: "-",
        total_power: "- TiB",
        migrate_total_price: "-.--",
      },
      passwordEnterShow: false,
    };
  },
  methods: {
    form_password_enter() {
      this.$message.destroy();
      this.passwordEnterShow = true;
    },
    handleSubmitForm() {
      this.$refs["formModel"].validate(async (valid) => {
        if (valid) {
          let res = await postUserServerMigrationPayment({
            pay_pwd: this.$axios.Base64.encode(this.formModel.item.a),
          });
          if (res.code === 0) {
            this.$message.success(res.message);
            setTimeout(() => {
              this.$router.go(-1);
            }, 1000);
          }
        }
      });
    },
    async getUserServerMigrationInfo() {
      // 获取数据
      Object.assign(
        this.MigrationInfo,
        (await getUserServerMigrationInfo()).data
      );
    },
  },
  created() {
    this.getUserServerMigrationInfo();
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less" scoped>
#Form {
  margin-top: 1em;
}
</style>

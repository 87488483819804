import { render, staticRenderFns } from "./Activity-Server-Migration.vue?vue&type=template&id=73e6d546&scoped=true&"
import script from "./Activity-Server-Migration.vue?vue&type=script&lang=js&"
export * from "./Activity-Server-Migration.vue?vue&type=script&lang=js&"
import style0 from "./Activity-Server-Migration.vue?vue&type=style&index=0&id=73e6d546&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73e6d546",
  null
  
)

export default component.exports

<template>
  <div id="password-enter">
    <a-modal
      v-model="show"
      id="password-enter"
      :centered="true"
      :closable="false"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="cancel"
    >
      <template slot="footer">
        <a-button-group class="btn-group">
          <a-button @click="cancel" type="primary">取消</a-button>
          <a-button @click="ok">确认</a-button>
        </a-button-group>
      </template>
      <a-form-model
        ref="passwordFormModel"
        :model="formModel.item"
        :rules="formModel.rules"
      >
        <a-form-model-item has-feedback prop="password" label="请输入密码">
          <a-input-password
            v-model="formModel.item.password"
            placeholder="请输入密码"
            @change="$emit('update:pwd', formModel.item.password)"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { Modal } from "ant-design-vue";
Vue.use(Modal);

export default {
  name: "password-enter",
  beforeCreate() {},
  // 父组件在标签中传递过来的数据
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    pwd: {
      type: String,
      default: "",
    },
  },
  // 数据监听
  watch: {
    "formModel.item.password"(newVal) {
      this.$emit("update:pwd", newVal);
    },
  },
  // 数据
  data() {
    return {
      formModel: {
        item: {
          password: "",
        },
        rules: {
          password: [
            {
              required: true,
              trigger: ["change", "blur"],
              validator: (rule, value, callback) => {
                if (!value) callback(new Error("输入不能为空"));
                else callback();
              },
            },
          ],
        },
        password_enter_show: false,
      },
    };
  },
  // 方法
  methods: {
    ok() {
      this.$refs["passwordFormModel"].validate((valid) => {
        if (valid) this.$emit("ok");
      });
    },
    cancel() {
      this.$emit("update:pwd", "");
      this.$emit("update:show", false);
    },
  },
};
</script>

<!-- 添加"scoped"属性，以限制CSS仅对该组件 -->
<style lang="less">
#password-enter {
}
</style>

import axios from '@/Vue-Axios';

// post 支付服务器迁移费用
export async function postUserServerMigrationPayment(datas) {
  const btns = document.getElementsByTagName('button');
  // 禁用按键
  btns.forEach(dom => dom.setAttribute('disabled', 'disabled'));

  let res = await axios({
    method: 'post',
    url: `power/migrate-payment`,
    data: axios.qs.stringify(datas)
  });

  if (res.code !== 0)
    // 释放按键
    setTimeout(() => {
      btns.forEach(dom => dom.removeAttribute('disabled'))
    }, 300);

  return res;
}